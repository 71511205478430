<template>
    <div>
        <!-- Select Location -->
        <vx-card class="mt-8">
            <div class="card-title justify-between">
                <h2 class="w-auto">Select a Location</h2>
            </div>
            <div class="review-location">
                <div class="vx-row">
                    <div class="vx-col xl:w-1/3 lg:w-1/2 w-full select-location">
                        <v-select v-model="locationId" :reduce="locations => locations.LocationId" :label="'LocationName'" :options="locationOptions" :clearable="false" @input="onChange" />
                        <vs-button color="primary" type="filled" icon-pack="feather" icon="icon-refresh-cw" class="refresh-btn" @click="getLocationList()"></vs-button>
                    </div>
                </div>
            </div>
        </vx-card>

        <!-- Add Campaign, Manage Black List, Email Setting -->
        <div class="flex justify-between w-full float-left mt-8 mb-6 flex-wrap">
            <div class="float-left sm:w-auto w-full">
                <vs-button type="filled" class="small-btn mt-2 sm:w-auto w-full bg-actionbutton" @click="(addCampaignPopupActive = true), populateLandingPage()">{{ LabelConstant.buttonLabelAddCampaign }}</vs-button>
                <vs-button color="primary" type="filled" class="small-btn ml-0 sm:ml-4 mt-2 sm:w-auto w-full" @click="(emailBlackPopupActive = true), getBlackList()">{{ LabelConstant.buttonLabelManageBlockList }}</vs-button>
            </div>
            <vs-button color="primary" type="filled" class="small-btn mt-2 sm:w-auto w-full" @click="emailSettingPopupActive=true, getEmailSettings()">{{ LabelConstant.buttonLabelEmailSetting }}</vs-button>
        </div>

        <!-- Campaign List -->
        <vx-card class="location-table mb-8" v-for="(campaign, index) in campaignList" :key="index">
            <div class="card-title flex justify-between items-center">
                <a @click="
                (addCampaignPopupActive = true),
                (editCampaignMode = true),
                getCampaignId(campaign.CampaignId),
                (campaignPopupTitle = 'Edit Review Gathering Campaign')
            ">
                    <h2 class="text-secondary w-auto flex items-center">
                        <feather-icon icon="EditIcon" class="ml-0 mr-3 text-primary"></feather-icon>{{ campaign.CampaignName }}
                        <span v-if="campaign.IsFirstParty">(First Party)</span>
                        <span v-else> (Third Party) </span>
                    </h2>
                </a>
                <div class="float-left sm:w-auto w-full sm:mt-0 mt-4">
                    <vs-button type="filled" class="small-btn sm:w-auto w-full mt-2 bg-actionbutton" @click="
                (importCSVPopupActive = true), (campaignId = campaign.CampaignId)
                ">{{ LabelConstant.buttonLabelImportContacts }}</vs-button>
                    <vs-button color="primary" type="filled" class="small-btn sm:w-auto w-full ml-0 sm:ml-4 mt-2" @click="
                (newContactPopupActive = true), (campaignId = campaign.CampaignId)
                ">{{ LabelConstant.buttonLabelAddContact }}</vs-button>
                </div>
            </div>
            <vs-table :data="campaign.EmailStats">
                <template slot="thead">
                    <vs-th>Email Name</vs-th>
                    <vs-th>Send After</vs-th>
                    <vs-th>Pending</vs-th>
                    <vs-th>Sent</vs-th>
                    <vs-th>Opened</vs-th>
                    <vs-th>Clicked</vs-th>
                </template>
                <template slot-scope="{ data }">
                    <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                        <vs-td>
                            <span>
                                <a class="text-primary" @click="
                        (contactShowPopupActive = true),
                        (emailStateForTitle = data[indextr].TemplateName),
                        getEmailResults(data[indextr].MessageTemplateId)
                    ">{{ data[indextr].TemplateName }}
                                </a>
                            </span>
                        </vs-td>
                        <vs-td> {{ data[indextr].OnDay }} days </vs-td>
                        <vs-td>
                            {{ data[indextr].Pending }}
                        </vs-td>
                        <vs-td>
                            {{ data[indextr].Sent }}
                        </vs-td>
                        <vs-td>
                            {{ data[indextr].Opened }}
                        </vs-td>
                        <vs-td>
                            {{ data[indextr].Click }}
                        </vs-td>
                    </vs-tr>
                </template>
            </vs-table>
        </vx-card>

        <!-- No Campaign Message -->
        <vx-card class="location-table mb-8" v-if="!campaignList.length > 0">
            <div class="vx-row justify-between gather-header">
                <div class="vx-col  w-full md:w-1/2">
                    <h2 class="border-0 pb-0">
                        No Campaigns Configured
                    </h2>
                </div>
            </div>
            <div class="vx-row justify-between">
                <div class="vx-col w-full text-left md:text-right mt-3 md:mt-0 md:w-1/2">
                    <vs-button type="filled" class="ml-3 px-3 bg-actionbutton" @click="(addCampaignPopupActive = true), populateLandingPage()">{{ LabelConstant.buttonLabelAddCampaign }}</vs-button>
                </div>
            </div>
        </vx-card>

        <!-- Add Email to Block List -->
        <vs-popup class="tag-info" title="Add Email To Block List" :active.sync="addEmailBlockListPopupActive">
            <template>
                <div class="vx-row">
                    <div class="vx-col input-text w-full">
                        <div class="popup-icon">
                            <img src="../../assets/images/addplus.png" />
                        </div>
                        <form class="mt-6" data-vv-scope="addEmailToBlackListForm">
                            <vs-input type="email" class="w-full" label-placeholder="Email" v-model="addEmailToBlackList" name="addEmailToBlackList" v-validate="'required|email'" />
                            <span class="text-danger text-sm">{{ errors.first("addEmailToBlackListForm.addEmailToBlackList") }}</span>
                        </form>
                    </div>
                </div>
            </template>
            <div class="float-right mt-6 mb-8">
                <vs-button type="filled" class="mr-4" color="primary" @click="addToBlackList()">{{ LabelConstant.buttonLabelBlockEmail }}</vs-button>
                <vs-button color="grey" type="border" class=" grey-btn" @click="cancelAddToBlackList()">{{ LabelConstant.buttonLabelCancel }}</vs-button>
            </div>
        </vs-popup>

        <!-- Delete Blacked Email? -->
        <vs-popup class="delete-popup" title="Delete Black Email?" :active.sync="deleteBlackEmailPopupActive">
            <template>
                <div class="vx-row mb-3">
                    <div class="vx-col w-full">
                        <div class="popup-icon">
                            <feather-icon icon="Trash2Icon"></feather-icon>
                        </div>
                        <h4>Delete Block Email?</h4>
                        <span class="deleted-no">{{ deletableBlackEmail }}</span>
                    </div>
                </div>
            </template>
            <div class="float-right mt-6 mb-8">
                <vs-button type="filled" @click="deleteBlackListEntry()" color="danger">{{ LabelConstant.buttonLabelDelete }}</vs-button>
                <vs-button @click="deleteBlackEmailPopupActive = false" color="grey" type="border" class="ml-4 grey-btn">{{ LabelConstant.buttonLabelCancel }}</vs-button>
            </div>
        </vs-popup>

        <!-- Email Block List -->
        <vs-popup class="tag-info" title="Email Block List" :active.sync="emailBlackPopupActive">
            <template>
                <div class="vx-row">
                    <div class="vx-col w-full">
                        <vs-button color="success" @click="addEmailBlockListPopupActive = true" type="border" icon-pack="feather" icon="icon-plus" class="small-btn float-right">{{ LabelConstant.buttonLabelAddToList }}</vs-button>
                    </div>
                </div>
                <div class="vx-row">
                    <div class="vx-col w-full">
                        <!-- Actions Button -->
                        <div class="actions-button mb-2 float-left">
                                <vx-tooltip color="primary" text="EXCEL" class="float-left mr-4">
                                    <vs-button color="primary" type="filled">
                                        <download-excel :data="excelData" :exportFields="excelFields" worksheet="Email Campaign" name="Email Campaign.xls">
                                            <img src="@/assets/images/excel.svg" />
                                        </download-excel>
                                    </vs-button>
                                </vx-tooltip>
                            </div>
                        <div class="common-table">
                            
                            <vs-table ref="emailBlockTableData" class="user-table" id="emailBlockData" :data="manageBlockList" search :max-items="perPage" pagination>
                                <template slot="thead">
                                    <vs-th></vs-th>
                                    <vs-th>Email</vs-th>
                                </template>
                                <template slot-scope="{ data }">
                                    <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                                        <vs-td width="50">
                                            <vs-button color="danger" :title="LabelConstant.buttonLabelDelete" type="filled" icon-pack="feather" icon="icon-trash-2" class="mr-2 float-left" @click="
                                                (deleteBlackEmailPopupActive = true), (deletableBlackEmailId = tr.BlackListId), (deletableBlackEmail = tr.Email)"></vs-button>
                                        </vs-td>
                                        <vs-td :data="tr.Email"> {{ tr.Email }} </vs-td>
                                    </vs-tr>
                                </template>
                            </vs-table>
                            <!--Table Select Row-->
                            <div class="table-row-select pl-5 other-select">
                                <vs-select label="Show Entries" v-model="perPage" class="show-select" autocomplete>
                                    <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item, index) in recordList" />
                                </vs-select>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <div class="float-right mt-6 mb-8">
                <vs-button color="grey" class="grey-btn" @click="emailBlackPopupActive = false" type="border">{{ LabelConstant.buttonLabelClose }}</vs-button>
            </div>
        </vs-popup>

        <!-- Create New Contact Popup -->
        <vs-popup title="Create New Contact" :active.sync="newContactPopupActive">
            <template>
                <form data-vv-scope="createNewContactForm">
                    <div class="vx-row">
                        <div class="vx-col w-full mb-6">
                            <vs-input class="w-full" label="First Name" v-model="addContactFirstName" />
                        </div>
                        <div class="vx-col w-full mb-6">
                            <vs-input class="w-full" label="Last Name" v-model="addContactLastName" />
                        </div>
                        <div class="vx-col w-full mb-6">
                            <vs-input class="w-full" label="Email" v-model="addContactEmail" name="addContactEmail" v-validate="'required|email'" />
                            <span class="text-danger text-sm">{{ errors.first("createNewContactForm.addContactEmail") }}</span>
                        </div>
                        <div class="vx-col w-full mb-6">
                            <vs-input class="w-full" label="Phone" v-model="addContactPhone" />
                        </div>
                        <div class="vx-col w-full">
                            <vs-input class="w-full" label="Custom Field" v-model="addContactCustomeField" />
                        </div>
                    </div>
                </form>
            </template>
            <div class="float-right mt-6 mb-8">
                <vs-button type="filled" class="mr-4" color="primary" @click="addContact()">{{ LabelConstant.buttonLabelCreateContact }}</vs-button>
                <vs-button color="grey" class="grey-btn" @click="cancelAddContact()" type="border">{{ LabelConstant.buttonLabelCancel }}</vs-button>
            </div>
        </vs-popup>

        <!-- Import Contacts from CSV file -->
        <vs-popup class="tag-info" title="Import Contacts from CSV file" :active.sync="importCSVPopupActive">
            <div class="vx-row">
                <div class="vx-col w-full mb-10">
                    <h2 class="flex justify-between items-center review-popup-title flex-wrap">
                        Select File
                        <a href="/ExampleImport.csv" download class="text-secondary down-file">Download Sample CSV file</a>
                    </h2>
                    <div class="upload-img flex flex-wrap">
                        <div class="upload-input">
                            <feather-icon icon="UploadCloudIcon" class="primary-c"></feather-icon>
                            <span class="file-text">Upload File</span>
                            <input type="file" ref="file" id="csv_file" name="csv_file" @change="loadCSV($event)" />
                        </div>
                        <span class="file-name" v-if="csvFileName">{{ csvFileName }}</span>
                        <span class="text-danger text-sm" v-if="importContactFileError">{{ importContactFileError }}</span>
                    </div>
                </div>
                <div class="vx-col w-full">
                    <div class="vx-row">
                        <div class="vx-col w-full">
                            <h2 class="review-popup-title">Map Headers to Fields</h2>
                        </div>
                    </div>
                    <div class="vx-row">
                        <div class="vx-col w-full mb-6 lg:w-1/2">
                            <span>Email</span>
                            <v-select v-model="importEmailMapField" :options="importCSVFieldMapOptions" :reduce="options => options.id" :clearable="false" label="field" class="mt-0" />
                        </div>
                        <div class="vx-col w-full mb-6 lg:w-1/2">
                            <span>First Name</span>
                            <v-select v-model="importFirstNameMapField" :options="importCSVFieldMapOptions" :reduce="options => options.id" :clearable="false" label="field" class="mt-0" />
                        </div>
                        <div class="vx-col w-full mb-6 lg:w-1/2">
                            <span>Last Name</span>
                            <v-select v-model="importLastNameMapField" :options="importCSVFieldMapOptions" :reduce="options => options.id" :clearable="false" label="field" class="mt-0" />
                        </div>
                        <div class="vx-col w-full mb-6 lg:w-1/2">
                            <span>Phone</span>
                            <v-select v-model="importPhoneMapField" :options="importCSVFieldMapOptions" :reduce="options => options.id" :clearable="false" label="field" class="mt-0" />
                        </div>
                        <div class="vx-col w-full mb-6 lg:w-1/2">
                            <span>Custom Field</span>
                            <v-select v-model="importCustomeFieldMapField" :options="importCSVFieldMapOptions" :reduce="options => options.id" :clearable="false" label="field" class="mt-0" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="float-right mt-6 mb-8">
                <vs-button type="filled" class="mr-4" color="primary" @click="addListOfContacts()" :disabled="!isImportContactsButton">{{ LabelConstant.buttonLabelImportContacts }}</vs-button>
                <vs-button color="grey" class="grey-btn" @click="closeImportCvsPopup()" type="border">{{ LabelConstant.buttonLabelCancel }}</vs-button>
            </div>
        </vs-popup>

        <!-- Create New Drip Email -->
        <vs-popup class="popup-width" :title="dripEmailPopupTitle" v-if="isDripEmailPopupActive" :active.sync="addDripEmail">
            <template>
                <form data-vv-scope="createNewDripEmailForm">
                    <div class="drip-email existing-email-drip" v-show="!dripEmailEditMode">
                        <div class="vx-row">
                            <div class="vx-col w-full">
                                <h2>Copy Existing Email?</h2>
                            </div>
                            <div class="vx-col w-full select-temp">
                                <span class="temp-name">Select Template</span>
                                <v-select v-model="selectedTemplate" :options="messageTemplates" :reduce="options => options.index" label="TemplateName" :clearable="false" />
                                <vs-button type="border" class="small-btn" @click.prevent="
                        (isEmailTemplateCopied = true), copyEmailTemplate()
                    ">{{ LabelConstant.buttonLabelCopy }}</vs-button>
                            </div>
                        </div>
                    </div>
                    <div class="drip-email mt-8">
                        <div class="vx-row">
                            <div class="vx-col w-full">
                                <h2>Name your email sequence</h2>
                            </div>
                            <div class="vx-col w-full centerx labelx">
                                <vs-input label="Email Name" placeholder="My Template Name" v-model="emailName" class="w-full" name="emailName" v-validate="'required'" />
                                <span class="text-danger text-sm">{{
                    errors.first("createNewDripEmailForm.emailName")
                    }}</span>
                                <p class="float-left w-full mt-2">
                                    This must be a unique name.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="drip-email mt-8">
                        <div class="vx-row">
                            <div class="vx-col w-full">
                                <h2>Compose email to ask for reviews</h2>
                            </div>
                            <div class="vx-col w-full md:w-1/2 mb-6">
                                <vs-input class="w-full" label-placeholder="Email Subject" v-model="emailSubject" name="emailSubject" v-validate="'required'" />
                                <span class="text-danger text-sm">{{
                    errors.first("createNewDripEmailForm.emailSubject")
                    }}</span>
                            </div>
                            <div class="vx-col w-full md:w-1/2 mb-6">
                                <vs-input class="w-full" label-placeholder="Reply Email" v-model="replyEmail" name="replyEmail" v-validate="'required|email'" />
                                <span class="text-danger text-sm">{{
                    errors.first("createNewDripEmailForm.replyEmail")
                    }}</span>
                            </div>
                            <div class="vx-col w-full mb-8">
                                <span class="mr-4">Header Image</span>
                                <vs-button color="primary" type="border" @click.prevent="(selectImage = true), listMedia()" class="small-btn">{{ LabelConstant.buttonLabelSelectMedia }}</vs-button>
                                <span class="text-danger text-sm" v-if="imageErrorMessage">{{
                    imageErrorMessage
                    }}</span>
                            </div>
                            <div class="vx-col md:w-1/3 sm:w-1/2 w-full mb-8" v-if="image">
                                <div class="img-info">
                                    <img :src="'data:image/png;base64, ' + image" />
                                </div>
                            </div>
                            <div class="vx-col w-full mb-4">
                                <span>Message Body</span>
                                <quill-editor v-model="content" ref="myQuillEditor" name="content" v-validate="'required'" class="mt-2">
                                </quill-editor>
                                <span class="text-danger text-sm">{{
                    errors.first("createNewDripEmailForm.content")
                    }}</span>
                            </div>
                            <div class="vx-col w-full">
                                <span class="float-left mt-2 w-full sm:w-auto mr-0 sm:mr-4 mb-4 sm:mb-0">Insert Merge Tag</span>
                                <v-select v-model="selectInsertMerge" :reduce="insertMergeTagOptions => insertMergeTagOptions.value" :label="'text'" :options="insertMergeTagOptions" class="mt-0 float-left mr-0 sm:mr-4 w-full sm:w-1/3" :clearable="false" />
                                <vs-button type="filled" class="small-btn insert-btn mt-2 sm:mt-0 bg-actionbutton" @click.prevent="insertMergeTag()">{{ LabelConstant.buttonLabelInsertField }}</vs-button>
                            </div>
                        </div>
                    </div>
                    <div class="drip-email mt-8">
                        <div class="vx-row">
                            <div class="vx-col w-full">
                                <h2>Scheduling</h2>
                            </div>
                        </div>
                        <div class="vx-row">
                            <div class="vx-col w-full">
                                <div class="send-email mb-6">
                                    <span class="mr-2 small-title-text">Wait</span>
                                    <v-select v-model="onDay" :options="onDayOptions" :clearable="false" class="mt-0 float-left" />
                                    <span class="ml-0 small-title-text sm:ml-2  w-full sm:w-auto">days before sending this email.</span>
                                    <span class="text-danger error-msg-text text-sm" v-if="onDayErrorMessage">{{ onDayErrorMessage }}</span>
                                </div>
                                <div class="send-email mb-6">
                                    <span class="mr-0 small-title-text sm:mr-2 w-full sm:w-auto sm:mb-0 mb-2">Send Email at</span>
                                    <v-select v-model="timeHour" :options="hoursOptions" :clearable="false" class="mt-0 float-left mr-2" />
                                    <b>:</b>
                                    <v-select v-model="timeMinute" :options="minuteOptions" :clearable="false" class="mt-0 float-left mr-2 ml-2" />
                                    <b>:</b>
                                    <v-select v-model="AMPM" :reduce="AMPMOptions => AMPMOptions.value" :label="'text'" :options="AMPMOptions" :clearable="false" class="mt-0 float-left ml-2" />
                                    <span class="ml-2 small-title-text">on this day.</span>
                                </div>
                                <ul class="emailtypes">
                                    <li>
                                        <vs-radio v-model="sendRule" vs-value="1">Send only if never opened</vs-radio>
                                    </li>
                                    <li>
                                        <vs-radio v-model="sendRule" vs-value="2">Send only if never clicked</vs-radio>
                                    </li>
                                    <li>
                                        <vs-radio v-model="sendRule" vs-value="3">Always Send</vs-radio>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </form>
                <div class="drip-email mt-8">
                    <div class="vx-row">
                        <div class="vx-col w-full">
                            <h2>Test your email</h2>
                        </div>
                    </div>
                    <div class="vx-row">
                        <div class="vx-col w-full test-email">
                            <form data-vv-scope="testEmailForm">
                                <p>
                                    Would you like to send a test email to see what your request
                                    will look like?
                                </p>
                                <vs-input label="Email" placeholder="Email address to send example to" v-model="testEmailName" name="testEmailName" v-validate="'required|email'" />
                                
                                <vs-button class="bg-actionbutton" type="filled" @click="sendTestEmail()">{{ LabelConstant.buttonLabelSendTestEmail }}</vs-button>
                                <span class="text-danger text-sm">{{ errors.first("testEmailForm.testEmailName") }}</span>
                            </form>
                        </div>
                    </div>
                </div>
            </template>
            <div class="float-right mt-6 mb-8">
                <vs-button type="filled" @click="addMessageTemplate()" class="bg-actionbutton">{{ LabelConstant.buttonLabelSaveNewEmail }}</vs-button>
                <vs-button color="grey" @click="cancelNewDripEmail()" type="border" class="ml-4 grey-btn">{{ LabelConstant.buttonLabelCancel }}</vs-button>
            </div>
        </vs-popup>

        <!-- Email State Test Drip Email -->
        <vs-popup class="gather-review-email-stat-modal popup-width" :title="emailStateForTitle" :active.sync="contactShowPopupActive">
            <div class="vx-row">
                <div class="vx-col w-full">
                    <!-- Actions Button -->
                    <div class="flex w-full flex-wrap">
                        <div class="actions-button mb-2 float-left">
                            <vx-tooltip color="actionbutton" text="EXCEL" class="float-left mr-4">
                                <vs-button type="filled" class="bg-actionbutton">
                                    <download-excel :data="excelEmailStatsContactsFieldsData" :exportFields="excelEmailStatsContactsFields" worksheet="Review Gather" name="Review Gather.xls">
                                        <img src="../../assets/images/excel.svg" />
                                    </download-excel>
                                </vs-button>
                            </vx-tooltip>
                        </div>
                        <div class="common-table">
                            <!--Table Select Row-->
                            <div class="table-row-select pl-5">
                                <vs-select label="Show Entries" v-model="perPage" class="show-select" autocomplete>
                                    <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item, index) in recordList" />
                                </vs-select>
                            </div>
                            <vs-table id="emailStatsContactsData" ref="emailStatsContactsTableData" :data="emailStateFor" search :max-items="perPage" pagination>
                                <template slot="thead">
                                    <vs-th sort-key="LastName">LastName</vs-th>
                                    <vs-th sort-key="FirstName">FirstName</vs-th>
                                    <vs-th sort-key="Email">Email</vs-th>
                                    <vs-th sort-key="status">Status</vs-th>
                                </template>
                                <template slot-scope="{ data }">
                                    <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                                        <vs-td :data="tr.LastName">
                                            {{ tr.LastName }}
                                        </vs-td>
                                        <vs-td :data="tr.FirstName">
                                            {{ tr.FirstName }}
                                        </vs-td>
                                        <vs-td :data="tr.Email">
                                            {{ tr.Email }}
                                        </vs-td>
                                        <vs-td :data="tr.IsSent">
                                            <span v-if="tr.IsSent">Sent</span>
                                            <span v-else>Pending</span>
                                        </vs-td>
                                    </vs-tr>
                                </template>
                            </vs-table>                            
                        </div>
                    </div>
                </div>
            </div>
            <div class="float-right mt-6 mb-8">
                <vs-button color="grey" class="grey-btn" @click="contactShowPopupActive = false" type="border">{{ LabelConstant.buttonLabelCancel }}</vs-button>
            </div>
        </vs-popup>

        <!-- Select Media -->
        <vs-popup class="tag-info" title="Select an Image" :active.sync="selectImage">
            <div class="vx-row">
                <div class="vx-col input-text w-full mb-10">
                    <h2 class="review-popup-title">Add New File</h2>
                    <div class="upload-img flex flex-wrap">
                        <div class="upload-input">
                            <feather-icon icon="UploadCloudIcon" class="primary-c"></feather-icon>
                            <span class="file-text">Upload File</span>
                            <input type="file" ref="mediaFile" v-on:change="handleFileUpload" />
                        </div>
                    </div>
                </div>
                <div class="vx-col input-text w-full">
                    <h2 class="review-popup-title">Select Existing Image</h2>
                    <div class="existing-img">
                        <div class="vx-row">
                            <div class="vx-col md:w-1/3 sm:w-1/2 w-full mb-8" v-for="(media, index) in mediaList" :key="index">
                                <div class="img-info">
                                    <img :src="'data:image/png;base64, ' + media.FileContents" />
                                </div>
                                <div class="img-actions">
                                    <vs-button radius color="success" type="filled" icon-pack="feather" icon="icon-check-circle" @click="(image = media.FileContents), (selectImage = false)"></vs-button>
                                    <vs-button radius color="danger" type="filled" icon-pack="feather" icon="icon-trash-2" class="mt-2" @click="deleteMedia(media.MediaId)"></vs-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="float-right mt-6 mb-8">
                <vs-button color="grey" @click="selectImage = false" type="border" class="ml-4 grey-btn">{{ LabelConstant.buttonLabelCancel }}</vs-button>
            </div>
        </vs-popup>

        <!-- Delete Drip Email? -->
        <vs-popup class="delete-popup" title="Delete Email?" :active.sync="deleteDripEmailPopupActive">
            <template>
                <div class="vx-row mb-3">
                    <div class="vx-col w-full">
                        <div class="popup-icon">
                            <feather-icon icon="Trash2Icon"></feather-icon>
                        </div>
                        <h4>Delete Email?</h4>
                    </div>
                </div>
            </template>
            <div class="float-right mt-6 mb-8">
                <vs-button type="filled" color="danger" @click="deleteDripEmail()">{{ LabelConstant.buttonLabelDelete }}</vs-button>
                <vs-button @click.prevent="deleteDripEmailPopupActive = false" color="grey" type="border" class="ml-4 grey-btn">{{ LabelConstant.buttonLabelCancel }}</vs-button>
            </div>
        </vs-popup>

        <!-- Add Campaign Popup -->
        <vs-popup class="popup-width" :title="campaignPopupTitle" :active.sync="addCampaignPopupActive">
            <template>
                <div class="vx-row md:mb-8 mb-4">
                    <div class="vx-col w-full">
                        <vs-progress :percent="progressBar" color="secondary"></vs-progress>
                    </div>
                </div>
                <div class="vx-row">
                    <div class="vx-col w-full">
                        <!-- STEP1: 1st Party Or 3rd Party -->
                        <div class="review-info review-info-block" v-if="activeStep == 1">
                            <h2>Please tell us how you will use these reviews.</h2>
                            <div class="reviews-list">
                                <h4>First Party Reviews</h4>
                                <div class="email-list">
                                    <ul>
                                        <li>Only recorded Internally</li>
                                        <li>Good for measuring feedback</li>
                                        <li>Not shared on review sites</li>
                                    </ul>
                                    <vs-button color="secondary" type="filled" class="small-btn mt-4" @click="nextStep(2), (IsFirstParty = true)">{{ LabelConstant.buttonLabel1stPartyInternal }}</vs-button>
                                </div>
                            </div>
                            <div class="reviews-list">
                                <h4>Third Party Reviews</h4>
                                <div class="email-list">
                                    <ul>
                                        <li>Recorded on external sites</li>
                                        <li>Provides credibility to your business</li>
                                        <li>Shared on review sites</li>
                                    </ul>
                                    <vs-button color="secondary" type="filled" class="small-btn mt-4" @click="nextStep(2), (IsFirstParty = false)">{{ LabelConstant.buttonLabel3rdPartyExternal }}</vs-button>
                                </div>
                            </div>
                        </div>
                        <!-- STEP2: Email only Or Email with sentiment -->
                        <div class="review-info review-info-block" v-if="activeStep == 2">
                            <h2>
                                Please tell us how you will use your email to gather reviews.
                            </h2>
                            <div class="reviews-list">
                                <h4>Email Only</h4>
                                <div class="email-list">
                                    <p>
                                        Email is sent to customer with links to the review sites.
                                        List of sites is configured by you.
                                    </p>
                                    <ul>
                                        <li>Higher chance of receiving review</li>
                                        <li>Most accepted by review sites</li>
                                        <li>Less control</li>
                                    </ul>
                                    <vs-button color="secondary" type="filled" class="small-btn mt-4" @click="(IsEmailToLandingPage = false), nextStep(4)">{{ LabelConstant.buttonLabelEmailOnly }}</vs-button>
                                </div>
                            </div>
                            <div class="reviews-list">
                                <h4>Email with Sentiment</h4>
                                <div class="email-list">
                                    <p>
                                        Email sent to customer with a rating request. Based on the
                                        response, the recipient will be sent to a custom landing
                                        page of your choice.
                                    </p>
                                    <ul>
                                        <li>More control of user flow</li>
                                        <li>Less likely to receive reviews</li>
                                        <li>
                                            Specialized landing page to handle less satisfied
                                            customers
                                        </li>
                                    </ul>
                                    <vs-button color="secondary" type="filled" class="small-btn mt-4" @click="(IsEmailToLandingPage = true), nextStep(3)">{{ LabelConstant.buttonLabelEmailWithSentiment }}</vs-button>
                                </div>
                            </div>
                        </div>
                        <!-- STEP3: Select Email Sentiment Type -->
                        <div class="review-info" v-if="activeStep == 3">
                            <div class="email-sentiment mb-8">
                                <h2>Select Email Sentiment Type</h2>
                                <div class="sentiment-type mt-0">
                                    <vs-radio v-model="emailSentimentType" vs-value="stars" @change="changeSentimentTypeDropdownOptions">Stars</vs-radio>
                                    <star-rating :show-rating="false" :rating="5" :read-only="true" active-color="#ECB940" class="pl-8"></star-rating>
                                </div>
                                <div class="sentiment-type mt-0">
                                    <vs-radio v-model="emailSentimentType" vs-value="hearts" @change="changeSentimentTypeDropdownOptions">Hearts</vs-radio>
                                    <ul class="rating-list pl-8">
                                        <li>
                                            <feather-icon icon="HeartIcon" class="text-danger"></feather-icon>
                                        </li>
                                        <li>
                                            <feather-icon icon="HeartIcon" class="text-danger"></feather-icon>
                                        </li>
                                        <li>
                                            <feather-icon icon="HeartIcon" class="text-danger"></feather-icon>
                                        </li>
                                        <li>
                                            <feather-icon icon="HeartIcon" class="text-danger"></feather-icon>
                                        </li>
                                        <li>
                                            <feather-icon icon="HeartIcon" class="text-danger"></feather-icon>
                                        </li>
                                    </ul>
                                </div>
                                <div class="sentiment-type">
                                    <vs-radio v-model="emailSentimentType" vs-value="thumbs" @change="changeSentimentTypeDropdownOptions">Thumbs</vs-radio>
                                    <ul class="rating-list pl-8">
                                        <li>
                                            <feather-icon icon="ThumbsUpIcon" class="text-success"></feather-icon>
                                        </li>
                                        <li class="pl-2">
                                            <feather-icon icon="ThumbsDownIcon" class="text-danger"></feather-icon>
                                        </li>
                                    </ul>
                                </div>
                                <div class="sentiment-type">
                                    <vs-radio v-model="emailSentimentType" vs-value="numbers" @change="changeSentimentTypeDropdownOptions">Numbers</vs-radio>
                                    <ul class="edit-numbers">
                                        <li>1</li>
                                        <li>2</li>
                                        <li>3</li>
                                        <li>4</li>
                                        <li>5</li>
                                        <li>6</li>
                                        <li>7</li>
                                        <li>8</li>
                                        <li>9</li>
                                        <li>10</li>
                                    </ul>
                                </div>
                            </div>
                            <div class="email-sentiment">
                                <div class="vx-row">
                                    <div class="vx-col w-full">
                                        <h2>Select Action</h2>
                                    </div>
                                    <div class="vx-col w-full mb-6">
                                        <span class="mb-2 feedback-title">How many for positive feedback?</span>
                                        <v-select v-model="positiveFeedbackScore" :options="positiveFeedbackScoreOptions" :clearable="false" class="mt-0" />
                                    </div>
                                    <div class="vx-col w-full mb-6">
                                        <span class="mb-2 feedback-title">Send positive feedback to the following landing
                                            page</span>
                                        <v-select v-model="positiveLandingPageId" :reduce="
                            landingPageOptions => landingPageOptions.LandingPageId
                        " :label="'LandingPageName'" :options="landingPageOptions" @input="changePositiveLandingPageLink" :clearable="false" class="mt-0 select-more" />
                                        <a :href="positiveLandingPageLink" target="_blank" class="show-item">Show</a>
                                    </div>
                                    <div class="vx-col w-full">
                                        <span class="mb-2 feedback-title">Send all other feedback to the following landing
                                            page</span>
                                        <v-select v-model="negativeLandingPageId" :reduce="
                            landingPageOptions => landingPageOptions.LandingPageId
                        " :label="'LandingPageName'" :options="landingPageOptions" @input="changeNegativeLandingPageLink" :clearable="false" class="mt-0 select-more" />
                                        <a :href="negativeLandingPageLink" target="_blank" class="show-item">Show</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- STEP4: Campaign Name, Review Widget Location or Campaign Name, Review Sources -->
                        <!-- STEP4A: 1st Party --->
                        <div class="review-info" v-if="activeStep == 4 && IsFirstParty">
                            <form data-vv-scope="addCampaignForm">
                                <div class="vx-row mb-8">
                                    <div class="vx-col w-full">
                                        <h2>Campaign Name</h2>
                                    </div>
                                    <div class="vx-col w-full">
                                        <vs-input class="w-full mt-2" label-placeholder="Campaign Name" v-model="campaignName" name="campaignName" v-validate="'required'" />
                                        <span class="text-danger text-sm">{{
                        errors.first("addCampaignForm.campaignName")
                        }}</span>
                                    </div>
                                </div>
                                <div class="vx-row">
                                    <div class="vx-col w-full">
                                        <h2>Review Widget Location</h2>
                                    </div>
                                    <div class="vx-col w-full">
                                        <vs-input class="w-full mt-2" label-placeholder="Widget Url" v-model="widgetUrl" name="widgetUrl" v-validate="'required|url:require_protocol'" />
                                        <span class="text-danger text-sm">{{
                        errors.first("addCampaignForm.widgetUrl")
                        }}</span>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <!-- STEP4B: 3rd Party --->
                        <div class="review-info" v-if="activeStep == 4 && !IsFirstParty">
                            <div class="vx-row mb-8">
                                <div class="vx-col w-full">
                                    <h2>Name and Review Sources</h2>
                                </div>
                            </div>
                            <div class="vx-row mb-8">
                                <div class="vx-col w-full">
                                    <form data-vv-scope="addCampaignForm">
                                        <vs-input class="w-full mt-2" label-placeholder="Campaign Name" v-model="campaignName" name="campaignName" v-validate="'required'" />
                                        <span class="text-danger text-sm">{{
                        errors.first("addCampaignForm.campaignName")
                        }}</span>
                                    </form>
                                </div>
                            </div>
                            <div class="vx-row">
                                <div class="vx-col w-full">
                                    <h2>Select Review Sources</h2>
                                </div>
                                <div class="vx-col w-full lg:w-1/2 mb-6" v-for="(reviewSource, index) in reviewSources" :key="index">
                                    <vs-checkbox class="ml-0 float-left" :vs-value="reviewSource" v-model="selectedReviewSourceChecked[index]" @change="pushPopReviewSource($event, reviewSource, index)">{{ reviewSource.SourceName }}</vs-checkbox>
                                    <span class="float-left ml-8">{{
                        reviewSource.ListingStatus
                    }}</span>
                                    <vs-input v-show="selectedReviewSourceChecked[index]" class="w-full mt-2 float-left" placeholder="Enter review site URL override if necessary." v-model="reviewSiteUrl[index]" />
                                </div>
                                <span class="text-danger text-sm">{{
                    reviewCheckErrorMessage
                    }}</span>
                            </div>
                        </div>
                        <!-- STEP5: Configure Drip Email Sequence -->
                        <div class="review-info" v-if="activeStep == 5">
                            <h2>Configure Drip Email Sequence</h2>
                            <vs-button color="primary" type="filled" class="small-btn mb-3" @click="
                    (addDripEmail = true),
                        listMessageTemplates(),
                        (isDripEmailPopupActive = true),
                        (addCampaignPopupActive = false)
                    ">{{ LabelConstant.buttonLabelAddDripEmail }}</vs-button>
                            <vs-table :data="addMessageTemplates">
                                <template slot="thead">
                                    <vs-th>{{ LabelConstant.tableHeaderLabelEmailName }}</vs-th>
                                    <vs-th>{{ LabelConstant.tableHeaderLabelSendAfter }}</vs-th>
                                    <vs-th>{{ LabelConstant.tableHeaderLabelSendTime }}</vs-th>
                                    <vs-th>{{ LabelConstant.tableHeaderLabelOptions }}</vs-th>
                                </template>
                                <template slot-scope="{ data }">
                                    <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                                        <vs-td :data="data[indextr].TemplateName">
                                            {{ data[indextr].TemplateName }}
                                        </vs-td>
                                        <vs-td :data="data[indextr].OnDay">
                                            {{ data[indextr].OnDay }} days
                                        </vs-td>
                                        <vs-td :data="data[indextr].TimeHour">
                                            {{ data[indextr].TimeHour }}:{{
                            data[indextr].TimeMinute | minutesFormat
                        }}
                                            {{ data[indextr].AMPM }}
                                        </vs-td>
                                        <vs-td class="table-action-btn">
                                            <vs-button type="filled" icon-pack="feather" icon="icon-edit" class="float-left bg-actionbutton" @click="
                            editMessageDripEmailTemplate(indextr),
                                (dripEmailPopupTitle = 'Edit Drip Email'),
                                (dripEmailEditMode = true),
                                (isDripEmailPopupActive = true),
                                (addCampaignPopupActive = false)
                            "></vs-button>
                                            <vs-button color="danger" type="filled" icon-pack="feather" icon="icon-trash-2" class="float-left ml-2" @click="
                            (deleteDripEmailPopupActive = true),
                                (deletableDripEmailIndex = indextr)
                            "></vs-button>
                                        </vs-td>
                                    </vs-tr>
                                </template>
                            </vs-table>
                            <span class="text-danger text-sm" v-if="addMessageTemplates.length <= 0">{{ addMessageTemplatesErrorMessage }}</span>
                        </div>
                    </div>
                </div>
            </template>
            <div class="flex justify-between modal-action-buttons mt-6 w-full float-left flex-wrap mb-8">
                <div class="flex w-full flex-wrap">
                    <vs-button type="filled" class="mt-2 w-full sm:w-auto" color="primary" v-if="activeStep != 1 && !editCampaignMode" @click="back()">{{ LabelConstant.buttonLabelBack }}</vs-button>
                    <vs-button type="filled" class="mt-2 w-full sm:w-auto" color="primary" v-if="activeStep == 5 && editCampaignMode" @click="back()">{{ LabelConstant.buttonLabelBack }}</vs-button>
                </div>
                <div class="flex w-full flex-wrap justify-end">
                    <vs-button color="grey" @click="closeAddCampaignPopup()" type="border" class="mt-2 grey-btn w-full sm:w-auto">{{ LabelConstant.buttonLabelCancel }}</vs-button>
                    <vs-button type="filled" class="mt-2 sm:ml-4 ml-0 w-full sm:w-auto" color="primary" v-if="activeStep == 3" @click="nextStep(4)">{{ LabelConstant.buttonLabelNextCampaignName }}</vs-button>
                    <vs-button type="filled" class="mt-2 sm:ml-4 ml-0 w-full sm:w-auto" color="primary" v-if="activeStep == 4" @click="nextStep(5)">{{ LabelConstant.buttonLabelNextConfigureDripEmails }}</vs-button>
                    <vs-button type="filled" class="mt-2 sm:ml-4 ml-0 w-full sm:w-auto" color="primary" v-if="activeStep == 5 && !editCampaignMode" @click="addCampaign()">{{ LabelConstant.buttonLabelFinishProvision }}</vs-button>
                    <vs-button type="filled" class="mt-2 sm:ml-4 ml-0 w-full sm:w-auto" color="primary" v-if="activeStep == 5 && editCampaignMode" @click="updateCampaign()">{{ LabelConstant.buttonLabelFinishUpdateCampaign }}</vs-button>
                </div>
            </div>
        </vs-popup>

        <!-- Edit Review -->
        <vs-popup class="tag-info" title="Edit Email Settings" :active.sync="emailSettingPopupActive">
            <template>
                <div class="vx-row mb-4">
                    <div class="vx-col w-full">
                        <vs-checkbox v-model="emailSettings.IsOverride">Send email through my email server</vs-checkbox>
                    </div>
                </div>
                <form data-vv-scope="updateEmailSettingsForm" v-show="emailSettings.IsOverride">
                    <div class="vx-row mb-4">
                        <div class="vx-col w-full">
                            <vs-input class="w-full" label="Email Server Name" v-model="emailSettings.EmailServer"  v-validate="'required'" name="EmailServer"/>
                            <span class="text-danger text-sm">{{ errors.first('updateEmailSettingsForm.EmailServer') }}</span>
                        </div>
                    </div>
                    <div class="vx-row mb-4">
                        <div class="vx-col w-full">
                            <vs-input class="w-full" label="Email Server Port" v-model="emailSettings.EmailPort" v-validate="'required'" name="EmailPort"/>
                            <span class="text-danger text-sm">{{ errors.first('updateEmailSettingsForm.EmailPort') }}</span>
                        </div>
                    </div>
                    <div class="vx-row mb-6">
                        <div class="vx-col w-full">
                            <vs-input class="w-full" label="Email User Name" v-model="emailSettings.EmailUserName" v-validate="'required'" name="EmailUserName"/>
                            <span class="text-danger text-sm">{{ errors.first('updateEmailSettingsForm.EmailUserName') }}</span>
                        </div>
                    </div>
                    <div class="vx-row mb-6">
                        <div class="vx-col w-full">
                            <vs-input type="password" class="w-full" label="Email Password" v-model="emailSettings.EmailPassword" v-validate="'required'" name="EmailPassword"/>
                            <span class="text-danger text-sm">{{ errors.first('updateEmailSettingsForm.EmailPassword') }}</span>
                        </div>
                    </div>
                    <div class="vx-row mb-10">
                        <div class="vx-col w-full">
                            <vs-checkbox v-model="emailSettings.RequiresSSL">Server Requires SSL</vs-checkbox>
                        </div>
                    </div>
                    <div class="vx-row mb-4">
                        <div class="vx-col w-full">
                            <vs-input class="w-full" label="Send Email As" v-model="emailSettings.DefaultSendAsEmail" v-validate="'required|email'" name="DefaultSendAsEmail"/>
                            <span class="text-danger text-sm">{{ errors.first('updateEmailSettingsForm.DefaultSendAsEmail') }}</span>
                        </div>
                    </div>
                    <div class="vx-row mb-4">
                        <div class="vx-col w-full">
                            <vs-input class="w-full" label="Reply To Email" v-model="emailSettings.DefaultReplyToEmail" v-validate="'required|email'" name="DefaultReplyToEmail"/>
                            <span class="text-danger text-sm">{{ errors.first('updateEmailSettingsForm.DefaultReplyToEmail') }}</span>
                        </div>
                    </div>                
                </form>           
            </template>                
            <div class="float-right mt-6 sm:w-auto w-full mb-8">            
                <vs-button type="filled" class="sm:w-auto ml-0 mb-2 sm:mb-0 w-full bg-actionbutton" @click="updateEmailSettings()">{{ LabelConstant.buttonLabelUpdateEmailSettings }}</vs-button>
                <vs-button @click="cancelEmailSettings()" color="grey" type="border" class="grey-btn sm:ml-4 ml-0 sm:w-auto w-full">{{ LabelConstant.buttonLabelCancel }}</vs-button>
            </div>            
        </vs-popup>
    </div>
</template>

<script>
import VxCard from "@/components/vx-card/VxCard";
import DateRangePicker from "../../components/filter/DateRangePicker";
import vSelect from "vue-select";
import StarRating from "vue-star-rating";
import Vue from "vue";
import VueQuillEditor from "vue-quill-editor";

// require styles
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

/* export excel */
import JsonExcel from "vue-json-excel";

import {
    Validator
} from "vee-validate";

Vue.use(VueQuillEditor /* { default global options } */ );

const dict = {
    custom: {
        addEmailToBlackList: {
            required: "Please enter email address.",
            email: "Please enter a valid email address."
        },
        addContactEmail: {
            required: "Please enter email address.",
            email: "Please enter a valid email address."
        },

        campaignName: {
            required: "Please enter a campaign Name."
        },
        widgetUrl: {
            required: "Please enter a valid url.",
            url: "Please enter a valid url."
        },

        emailName: {
            required: "Please enter an Email Name."
        },
        emailSubject: {
            required: "Please enter a subject."
        },
        replyEmail: {
            required: "Please enter a reply email.",
            email: "Please enter a valid reply email."
        },
        content: {
            required: "Please enter an email message body."
        },
        testEmailName: {
            required: "Please enter email address.",
            email: "Please enter a valid email address."
        },

        //Email Settings
        EmailServer: {
            required: "Please enter a email server name."
        },
        EmailPort: {
            required: "Please enter a email server port."
        },
        EmailUserName: {
            required: "Please enter a email user name."
        },
        EmailPassword: {
            required: "Please enter a email password."
        },
        DefaultSendAsEmail: {
            required: "Please enter a email.",
            email: "Please enter a valid email."
        },
        DefaultReplyToEmail: {
            required: "Please enter a email.",
            email: "Please enter a valid email."
        },
    }
};

Validator.localize("en", dict);

export default {
    components: {
        VxCard,
        vSelect,
        DateRangePicker,
        StarRating,
        downloadExcel: JsonExcel
    },
    data() {
        return {
            // Locations
            locationId: null,
            locationOptions: [],

            //Store list of campaign for selected location
            campaignList: [],
            //Store list of review sources for Third Party Reviews
            reviewSources: [],

            emailSettingPopupActive: false,
            emailSettings: {
                Id: null,
                LocationId: null,
                IsOverride: null,
                EmailServer: null,
                EmailUserName: null,
                EmailPassword: null,
                EmailPort: null,
                RequiresSSL: null,
                DefaultSendAsEmail: null,
                DefaultReplyToEmail: null
            },

            //Store boolean value for Email Black List popup open or close
            emailBlackPopupActive: false,
            //Store list of blacked email
            manageBlockList: [],
            //Email Black List excel export field
            excelFields: {
                Email: "Email"
            },
            //Email Black List excel export data
            excelData: [],

            json_meta: [
                [{
                    key: "charset",
                    value: "utf-8"
                }]
            ],

            //Store boolean value for Add Email To Black List popup open or close
            addEmailBlockListPopupActive: false,
            //Store email that would be blacked
            addEmailToBlackList: null,

            //Store boolean value for Delete Blacked Email popup open or close
            deleteBlackEmailPopupActive: false,
            //Store email id that would be delete
            deletableBlackEmailId: null,
            //Store email that would be delete
            deletableBlackEmail: null,

            //Store boolean value for Create New Contact popup open or close
            newContactPopupActive: false,
            //Store contact details that would be created
            campaignId: null,
            addContactFirstName: null,
            addContactLastName: null,
            addContactEmail: null,
            addContactPhone: null,
            addContactCustomeField: null,

            //Store boolean value for Import Contacts from CSV File popup open or close
            importCSVPopupActive: false,
            importContactFileError: null,
            //Store selected field from csv for mapping
            importEmailMapField: null,
            importFirstNameMapField: null,
            importLastNameMapField: null,
            importPhoneMapField: null,
            importCustomeFieldMapField: null,

            //Store list of csv fields for mapping dropdown
            importCSVFieldMapOptions: [],
            //Store name of csv file that would be imported
            csvFileName: null,
            csv: null,
            sortOrders: {},
            sortKey: "",
            //Store boolean value for Import Contact button enable or disable
            isImportContactsButton: false,

            //Store boolean value for Email Stats popup open or close
            contactShowPopupActive: false,
            //Title of Email Stats popup
            emailStateForTitle: null,
            //Store list of Email Stats contacts
            emailStateFor: [],
            //Contact List excel export field
            excelEmailStatsContactsFields: {
                "Last Name": "LastName",
                "First Name": "FirstName",
                Email: "Email",
                Status: "IsSent"
            },
            //Email stats contacts List excel export data
            excelEmailStatsContactsFieldsData: [],

            campaignPopupTitle: "Create New Review Gathering Campaign",

            //Store boolean value for Add Campaign popup open or close
            addCampaignPopupActive: false,
            //Manage Create New Review Gathering (Add Campaign) progress bar
            activeStep: 1,
            completedStepArr: [],
            progressBar: 20,

            //Is 1st Party or 3rd Party
            IsFirstParty: null,

            //Email only OR Email with sentiment
            IsEmailToLandingPage: null,

            //Select Email Sentiment Type
            emailSentimentType: "stars",
            //Select Action
            positiveFeedbackScore: "3",
            positiveFeedbackScoreOptions: ["1", "2", "3", "4", "5"],
            positiveLandingPageId: null,
            negativeLandingPageId: null,
            landingPageOptions: [],
            positiveLandingPageLink: null,
            negativeLandingPageLink: null,

            //Name of campaign
            campaignName: null,
            //Review Widget Location
            widgetUrl: null,
            //Store list of selected Review sources
            selectedReviewSource: [],
            //Store list of selected and non-selected Review sources with true and false value respectively
            selectedReviewSourceChecked: [],
            //Store Select Review Sources
            reviewSiteUrl: [],
            reviewCheckErrorMessage: null,

            dripEmailPopupTitle: "Create New Drip Email",
            isDripEmailPopupActive: false,

            dripEmailEditMode: false,

            //Store boolean value for Create New Drip Email popup open or close
            addDripEmail: false,
            //list of current email template
            messageTemplates: [],

            //Store list of email template that would be created
            addMessageTemplates: [],
            addMessageTemplatesErrorMessage: null,

            //Store boolean value for Edit Review Gathering Campaign (Edit Campaign) mode true or false
            editCampaignMode: false,

            //Store boolean value to determine whether email template copied or not
            isEmailTemplateCopied: false,
            //Store id for selected message template
            selectedTemplate: null,
            insertMergeTagOptions: [{
                    text: "First Name",
                    value: "[[first_name]]"
                },
                {
                    text: "Last Name",
                    value: "[[last_name]]"
                },
                {
                    text: "Custom Field",
                    value: "[[custom_field]]"
                },
                {
                    text: "Review Links",
                    value: "[[review_links]]"
                }
            ],
            onDayOptions: [
                "0",
                "1",
                "2",
                "3",
                "4",
                "5",
                "6",
                "7",
                "8",
                "9",
                "10",
                "11",
                "12",
                "13",
                "14",
                "15",
                "16",
                "17",
                "18",
                "19",
                "20",
                "21",
                "22",
                "23",
                "24",
                "25",
                "26",
                "27",
                "28",
                "29",
                "30"
            ],
            hoursOptions: [
                "1",
                "2",
                "3",
                "4",
                "5",
                "6",
                "7",
                "8",
                "9",
                "10",
                "11",
                "12"
            ],
            minuteOptions: [
                "00",
                "05",
                "10",
                "15",
                "20",
                "25",
                "30",
                "35",
                "40",
                "45",
                "50",
                "55"
            ],
            AMPMOptions: [{
                    text: "AM",
                    value: "am"
                },
                {
                    text: "PM",
                    value: "pm"
                }
            ],

            //Store details of drip email template that would be created
            tempalateId: -1,
            emailName: null,
            emailSubject: null,
            replyEmail: null,
            image: "",
            imageErrorMessage: null,
            content: null,
            selectInsertMerge: "[[first_name]]",
            onDay: "0",
            onDayErrorMessage: null,
            timeHour: "10",
            timeMinute: "15",
            AMPM: "am",
            sendRule: 1,
            testEmailName: null,
            emailCampaignId: 0,
            emailCampaignName: null,
            emailGoogleReviewPrioritization: false,
            emailGreeting: "",
            emailTitlefont: "",
            emailisDeleted: false,
            editableMessageTemplateIndex: null,

            //Store boolean value for select image popup open or close
            selectImage: false,
            //Store list of media
            mediaList: [],
            file: "",

            //Store boolean value for Delete Email popup open or close
            deleteDripEmailPopupActive: false,
            deletableDripEmailIndex: null,

            //Data table
            perPage: 5,
            recordList: [{
                    text: "5",
                    value: 5
                },
                {
                    text: "10",
                    value: 10
                },
                {
                    text: "25",
                    value: 25
                },
                {
                    text: "50",
                    value: 50
                },
                {
                    text: "100",
                    value: 100
                }
            ],

            /* Popup */
            number: 5
        };
    },
    filters: {
        minutesFormat: function (value) {
            if (value.length == undefined) {
                return value > 9 ? "" + value : "0" + value;
            } else {
                if (value.length > 1) {
                    return value;
                } else {
                    return "0" + value;
                }
            }
        }
    },
    created() {
        this.getLocationList();
    },
    mounted() {
        let that = this;
        //Email Block List datatable record based on search parameter for excel
        setTimeout(() => {
            document
                .querySelector("#emailBlockData .input-search")
                .addEventListener("keyup", function () {
                    that.excelData = that.filterExport(true);
                });
        }, 1000);

        //Email Stat Contacts List datatable record based on search parameter for excel
        setTimeout(() => {
            document
                .querySelector("#emailStatsContactsData .input-search")
                .addEventListener("keyup", function () {
                    that.excelEmailStatsContactsFieldsData = that.filterExportEmailStatsContacts(
                        true
                    );
                });
        }, 1000);
    },
    computed: {
        editor() {
            return this.$refs.myQuillEditor.quill;
        }
    },
    methods: {
        //Update email settings
        updateEmailSettings(){
            if(this.emailSettings.IsOverride){
                this.$validator.validateAll("updateEmailSettingsForm").then(result => {
                    if (result) {
                        this.$vs.loading();
                        this.axios.post("/ws/ReviewGather/UpdateEmailSettings", this.emailSettings).then(() => {
                            this.cancelEmailSettings()
                            this.getEmailSettings()
                            this.$vs.loading.close();
                            this.$vs.notify({
                                title: "Success",
                                text: "Email settings have been updated successfully.",
                                color: "success",
                                iconPack: "feather",
                                icon: "icon-check",
                                position: "top-right",
                                time: 4000
                            });
                        }).catch(e => {
                            this.$vs.loading.close();
                            this.showError(e);
                                this.$vs.notify({
                                title: "Error",
                                text: e.response.data.Errors[0].Message,
                                color: "danger",
                                iconPack: "feather",
                                icon: "icon-alert-circle",
                                position: "top-right",
                                time: 4000
                            });
                        });
                    }
                });
            }else{
                this.$vs.loading();
                this.axios.post("/ws/ReviewGather/UpdateEmailSettings", this.emailSettings).then(() => {
                    this.cancelEmailSettings()
                    this.getEmailSettings()
                    this.$vs.loading.close();
                    this.$vs.notify({
                        title: "Success",
                        text: "Email settings have been updated successfully.",
                        color: "success",
                        iconPack: "feather",
                        icon: "icon-check",
                        position: "top-right",
                        time: 4000
                    });
                }).catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);
                        this.$vs.notify({
                        title: "Error",
                        text: e.response.data.Errors[0].Message,
                        color: "danger",
                        iconPack: "feather",
                        icon: "icon-alert-circle",
                        position: "top-right",
                        time: 4000
                    });
                });
            }
        },
        //Cancel email settings updation
        cancelEmailSettings(){
            this.emailSettingPopupActive = false
            this.emailSettings = {
                Id: null,
                LocationId: null,
                IsOverride: null,
                EmailServer: null,
                EmailUserName: null,
                EmailPassword: null,
                EmailPort: null,
                RequiresSSL: null,
                DefaultSendAsEmail: null,
                DefaultReplyToEmail: null    
            }
            setTimeout(() => {
                this.errors.clear("updateEmailSettingsForm");
            }, 20);
        },
        //Get email settings details
        getEmailSettings(){
            let getEmailSettingsParams = {
                LocationId: this.locationId
            };
            this.$vs.loading();
            this.axios.post("/ws/ReviewGather/GetEmailSettings", getEmailSettingsParams).then(response => {
                let data = response.data;
                this.emailSettings = data;
                setTimeout(() => {
                    this.errors.clear("updateEmailSettingsForm");
                }, 20);
                this.$vs.loading.close();
            }).catch(e => {
                this.$vs.loading.close();
                this.showError(e);
            });    
        },
        minutesFormat: function (value) {
            if (value.length == undefined) {
                return value > 9 ? "" + value : "0" + value;
            } else {
                if (value.length > 1) {
                    return value;
                } else {
                    return "0" + value;
                }
            }
        },
        onChange(value) {
            this.locationId = value;
            this.listCampaigns();
            this.getListingInfo();
            this.getLandingPages(this.locationId);
        },
        //Get locations
        getLocationList() {
            this.$vs.loading();
            this.axios
                .get("/ws/ReviewsGetStarted/GetLocationList")
                .then(response => {
                    let data = response.data;
                    if (data.length <= 0) {
                        this.$router.push(
                            "/reputation-management/getting-started/uid/" +
                            this.$route.params.id +
                            "?setLocation=true"
                        );
                    }
                    this.locationOptions = data;
                    this.locationId = data[0].LocationId;
                    setTimeout(() => {
                        this.listCampaigns();
                        this.getListingInfo();
                        this.getLandingPages(this.locationId);
                    }, 200);
                    this.$vs.loading.close();
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);
                });
        },
        //Get list of campaign for selected location
        listCampaigns() {
            let listCampaigns = {
                LocationId: this.locationId
            };
            this.$vs.loading();
            this.axios
                .post("/ws/ReviewGather/ListCampaigns", listCampaigns)
                .then(response => {
                    let data = response.data;
                    this.campaignList = data;
                    this.$vs.loading.close();
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);
                });
        },
        //Get list of block Email
        getBlackList() {
            this.$vs.loading();
            this.axios
                .get("/ws/ReviewGather/GetBlackList?id=" + this.locationId)
                .then(response => {
                    let data = response.data;
                    this.manageBlockList = data;

                    //Comparer Function
                    function GetSortOrder(prop) {
                        return function (a, b) {
                            if (a[prop] < b[prop]) {
                                return 1;
                            } else if (a[prop] > b[prop]) {
                                return -1;
                            }
                            return 0;
                        };
                    }

                    this.manageBlockList.sort(GetSortOrder("Email")); //Pass the attribute to be sorted on
                    //On API call assign all Block Email datatable data for excel export
                    this.excelData = this.filterExport();
                    this.$vs.loading.close();
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);
                });
        },
        filterExport(search = false) {
            let data = [];
            let emailBlockData = !search ?
                this.manageBlockList :
                this.$refs.emailBlockTableData.datax;

            for (var i = 0; i < emailBlockData.length; i++) {
                data.push({
                    Email: emailBlockData[i].Email
                });
            }
            return data;
        },
        //Close Add Email To Block List popup and clear all fields
        cancelAddToBlackList() {
            this.addEmailBlockListPopupActive = false;
            this.addEmailToBlackList = null;
            setTimeout(() => {
                this.errors.clear("addEmailToBlackListForm");
            }, 20);
        },
        //add email to block list
        addToBlackList() {
            this.$validator.validateAll("addEmailToBlackListForm").then(result => {
                if (result) {
                    let addToBlackListParams = {
                        LocationId: this.locationId,
                        Email: this.addEmailToBlackList
                    };
                    this.$vs.loading();
                    this.axios
                        .post("/ws/ReviewGather/AddToBlackList", addToBlackListParams)
                        .then(() => {
                            this.getBlackList();
                            this.cancelAddToBlackList();
                            this.$vs.notify({
                                title: "Success",
                                text: "Email added to block list.",
                                color: "success",
                                iconPack: "feather",
                                icon: "icon-check",
                                position: "top-right",
                                time: 4000
                            });
                            this.$vs.loading.close();
                        })
                        .catch(e => {
                            this.$vs.loading.close();
                            this.showError(e);
                            this.$vs.notify({
                                title: "Error",
                                text: e.response.data.Errors[0].Message,
                                color: "danger",
                                iconPack: "feather",
                                icon: "icon-alert-circle",
                                position: "top-right",
                                time: 4000
                            });
                        });
                }
            });
        },
        //Delete email from block list
        deleteBlackListEntry() {
            this.$vs.loading();
            this.axios
                .get(
                    "/ws/ReviewGather/DeleteBlackList?id=" + this.deletableBlackEmailId
                )
                .then(() => {
                    this.getBlackList();
                    (this.deleteBlackEmailPopupActive = false),
                    this.$vs.notify({
                        title: "Success",
                        text: "Email removed from block list.",
                        color: "success",
                        iconPack: "feather",
                        icon: "icon-check",
                        position: "top-right",
                        time: 4000
                    });
                    this.$vs.loading.close();
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);
                    this.$vs.notify({
                        title: "Error",
                        text: e.response.data.Errors[0].Message,
                        color: "danger",
                        iconPack: "feather",
                        icon: "icon-alert-circle",
                        position: "top-right",
                        time: 4000
                    });
                });
        },
        //Cancel Create New Contact popup and clear all fields
        cancelAddContact() {
            this.newContactPopupActive = false;
            this.campaignId = null;
            this.addContactCustomeField = null;
            this.addContactEmail = null;
            this.addContactFirstName = null;
            this.addContactLastName = null;
            this.addContactPhone = null;
            setTimeout(() => {
                this.errors.clear("createNewContactForm");
            }, 20);
        },
        //Add contact details
        addContact() {
            this.$validator.validateAll("createNewContactForm").then(result => {
                if (result) {
                    let addContactParams = {
                        CampaignId: this.campaignId,
                        ContactId: 0,
                        CustomField: this.addContactCustomeField,
                        Email: this.addContactEmail,
                        FirstName: this.addContactFirstName,
                        LastName: this.addContactLastName,
                        LocationId: this.commonLocationId,
                        Phone: this.addContactPhone
                    };
                    this.$vs.loading();
                    this.axios
                        .post("/ws/ReviewGather/AddContact", addContactParams)
                        .then(() => {
                            this.cancelAddContact();
                            this.$vs.notify({
                                title: "Success",
                                text: "Contact information has been added successfully.",
                                color: "success",
                                iconPack: "feather",
                                icon: "icon-check",
                                position: "top-right",
                                time: 4000
                            });
                            this.$vs.loading.close();
                        })
                        .catch(e => {
                            this.$vs.loading.close();
                            this.showError(e);
                            this.$vs.notify({
                                title: "Error",
                                text: e.response.data.Errors[0].Message,
                                color: "danger",
                                iconPack: "feather",
                                icon: "icon-alert-circle",
                                position: "top-right",
                                time: 4000
                            });
                        });
                }
            });
        },
        loadCSV(e) {
            if (
                this.$refs.file.files[0].type == "text/csv" ||
                this.$refs.file.files[0].type == "application/vnd.ms-excel"
            ) {
                this.isImportContactsButton = true;
                this.csvFileName = this.$refs.file.files[0].name;
                this.importContactFileError = null;
            } else {
                this.importContactFileError = "Please upload CSV file only.";
                this.$refs.file.value = "";
                this.csvFileName = null;
                this.importEmailMapField = null;
                this.importFirstNameMapField = null;
                this.importLastNameMapField = null;
                this.importPhoneMapField = null;
                this.importCustomeFieldMapField = null;
                this.importCSVFieldMapOptions = [];
                this.isImportContactsButton = false;
                return false;
            }
            var vm = this;
            if (window.FileReader) {
                var reader = new FileReader();
                reader.readAsText(e.target.files[0]);
                reader.onload = function (event) {
                    var csv = event.target.result;
                    vm.csv = vm.csvJSON(csv);
                };
                // reader.onerror = function(evt){
                //     if(evt.target.error.name = "NotReadableError"){
                //         alert("Cann't read this file!");
                //     }
                // };
            } else {
                alert("File Reader are not supported in this browser!");
            }
        },
        sortBy: function (key) {
            this.sortKey = key;
            this.sortOrders[key] = this.sortOrders[key] * -1;
        },
        csvJSON(csv) {
            var vm = this;
            var lines = csv.split("\n");
            var result = [];
            var headers = lines[0].split(",");
            // vm.importCSVFieldMapOptions = lines[0].split(",")
            // console.log(headers)
            for (let i = 0; i < headers.length; i++) {
                vm.importCSVFieldMapOptions.push({
                    id: i,
                    field: headers[i]
                });
            }
            // console.log(vm.importCSVFieldMapOptions)
            lines[0].split(",").forEach(function (key) {
                vm.sortOrders[key] = 1;
            });

            lines.map(function (line, indexLine) {
                if (indexLine < 1) return;

                var obj = {};
                var currentline = line.split(",");

                headers.map(function (header, indexHeader) {
                    obj[header] = currentline[indexHeader];
                });

                result.push(obj);
            });

            result.pop();

            return result;
        },
        //Close Import Contacts from CSV File popup and clear all fields
        closeImportCvsPopup() {
            this.importCSVPopupActive = false;
            this.importContactFileError = null;
            this.$refs.file.value = "";
            this.csvFileName = null;
            this.importEmailMapField = null;
            this.importFirstNameMapField = null;
            this.importLastNameMapField = null;
            this.importPhoneMapField = null;
            this.importCustomeFieldMapField = null;
            this.importCSVFieldMapOptions = [];
            this.isImportContactsButton = false;
            this.csv = null;
        },
        //Import csv of contact list
        addListOfContacts() {
            let locationId = this.locationId;
            let campaignId = this.campaignId;
            let importEmailMapField = this.importEmailMapField;
            let importFirstNameMapField = this.importFirstNameMapField;
            let importLastNameMapField = this.importLastNameMapField;
            let importPhoneMapField = this.importPhoneMapField;
            let importCustomeFieldMapField = this.importCustomeFieldMapField;

            this.csv.map(function (csvData) {
                let email = csvData.Email;
                let first = csvData.FirstName;
                let last = csvData.LastName;
                let phone = csvData.Phone;
                let customField = csvData.CustomField;

                csvData.Email =
                    importEmailMapField == 0 ?
                    email :
                    importEmailMapField == 1 ?
                    first :
                    importEmailMapField == 2 ?
                    last :
                    importEmailMapField == 3 ?
                    phone :
                    importEmailMapField == 3 ?
                    customField :
                    email;

                csvData.FirstName =
                    importFirstNameMapField == 0 ?
                    email :
                    importFirstNameMapField == 1 ?
                    first :
                    importFirstNameMapField == 2 ?
                    last :
                    importFirstNameMapField == 3 ?
                    phone :
                    importFirstNameMapField == 3 ?
                    customField :
                    first;

                csvData.LastName =
                    importLastNameMapField == 0 ?
                    email :
                    importLastNameMapField == 1 ?
                    first :
                    importLastNameMapField == 2 ?
                    last :
                    importLastNameMapField == 3 ?
                    phone :
                    importLastNameMapField == 4 ?
                    customField :
                    last;

                csvData.Phone =
                    importPhoneMapField == 0 ?
                    email :
                    importPhoneMapField == 1 ?
                    first :
                    importPhoneMapField == 2 ?
                    last :
                    importPhoneMapField == 3 ?
                    phone :
                    importPhoneMapField == 4 ?
                    customField :
                    phone;

                csvData.CustomField =
                    importCustomeFieldMapField == 0 ?
                    email :
                    importCustomeFieldMapField == 1 ?
                    first :
                    importCustomeFieldMapField == 2 ?
                    last :
                    importCustomeFieldMapField == 3 ?
                    phone :
                    importCustomeFieldMapField == 4 ?
                    customField :
                    customField;

                csvData.LocationId = locationId;
                csvData.CampaignId = campaignId;
                csvData.ContactId = -1;
                csvData.Id = 0;
                return csvData;
            });
            this.$vs.loading();
            this.axios
                .post("/ws/ReviewGather/AddListOfContacts", this.csv)
                .then(() => {
                    this.closeImportCvsPopup();
                    this.$vs.notify({
                        title: "Success",
                        text: "Contacts information has been added successfully.",
                        color: "success",
                        iconPack: "feather",
                        icon: "icon-check",
                        position: "top-right",
                        time: 4000
                    });
                    this.$vs.loading.close();
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);
                    this.$vs.notify({
                        title: "Error",
                        text: e.response.data.Errors[0].Message,
                        color: "danger",
                        iconPack: "feather",
                        icon: "icon-alert-circle",
                        position: "top-right",
                        time: 4000
                    });
                });
        },
        //Get list of contacts for particular email template
        getEmailResults(id) {
            this.$vs.loading();
            this.axios
                .get("/ws/ReviewGather/GetEmailResults?id=" + id)
                .then(response => {
                    let data = response.data;
                    this.emailStateFor = data;

                    //Comparer Function
                    function GetSortOrder(prop) {
                        return function (a, b) {
                            if (a[prop] < b[prop]) {
                                return 1;
                            } else if (a[prop] > b[prop]) {
                                return -1;
                            }
                            return 0;
                        };
                    }

                    this.emailStateFor.sort(GetSortOrder("FirstName")); //Pass the attribute to be sorted on
                    //On API call assign all Email stats contacts datatable data for excel export
                    this.excelEmailStatsContactsFieldsData = this.filterExportEmailStatsContacts();
                    this.$vs.loading.close();
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);
                });
        },
        filterExportEmailStatsContacts(search = false) {
            let data = [];
            let emailStatsContactsData = !search ?
                this.emailStateFor :
                this.$refs.emailStatsContactsTableData.datax;

            for (var i = 0; i < emailStatsContactsData.length; i++) {
                let status = emailStatsContactsData[i].IsSent ? "Sent" : "Pending";
                data.push({
                    LastName: emailStatsContactsData[i].LastName,
                    FirstName: emailStatsContactsData[i].FirstName,
                    Email: emailStatsContactsData[i].Email,
                    IsSent: status
                });
            }
            return data;
        },
        //Get landing pages
        getLandingPages() {
            this.$vs.loading();
            this.axios
                .get("/ws/ReviewGather/GetLandingPages?id=" + this.locationId)
                .then(response => {
                    let data = response.data;
                    this.landingPageOptions = data;
                    this.landingPageOptions.reverse();

                    this.$vs.loading.close();
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);
                });
        },
        populateLandingPage() {
            if (this.landingPageOptions.length > 0 && !this.editCampaignMode) {
                this.positiveLandingPageId = this.landingPageOptions[0].LandingPageId;
                this.negativeLandingPageId = this.landingPageOptions[0].LandingPageId;
                this.positiveLandingPageLink = this.landingPageOptions[0].LandingPageUrl;
                this.negativeLandingPageLink = this.landingPageOptions[0].LandingPageUrl;
            }
        },
        //Step next process for campaign create
        nextStep(nextActiveStep) {
            this.$validator.validateAll("addCampaignForm").then(result => {
                if (result) {
                    if (!this.IsFirstParty && this.activeStep == 4) {
                        for (let i = 0; i < this.selectedReviewSource.length; i++) {
                            if (Object.keys(this.selectedReviewSource[i]).length > 0) {
                                break;
                            }
                            if (this.selectedReviewSource.length == i + 1) {
                                this.reviewCheckErrorMessage =
                                    "Please select at least review source to include.";
                                return false;
                            }
                        }
                    }
                    this.reviewCheckErrorMessage = null;
                    this.completedStepArr.push(this.activeStep);
                    this.activeStep = nextActiveStep;
                    if (!this.editCampaignMode) {
                        this.progressBar = (this.completedStepArr.length + 1) * 20;
                    } else {
                        this.progressBar = (this.completedStepArr.length + 1) * 25;
                    }
                }
            });
        },
        //Step back process for campaign create
        back() {
            this.activeStep = this.completedStepArr.pop();
            if (!this.editCampaignMode) {
                this.progressBar =
                    this.completedStepArr.length > 0 ?
                    (this.completedStepArr.length + 1) * 20 :
                    20;
            } else {
                this.progressBar =
                    this.completedStepArr.length > 0 ?
                    (this.completedStepArr.length + 1) * 25 :
                    25;
            }
        },
        //Change Sentiment Type Dropdown Options
        changeSentimentTypeDropdownOptions() {
            this.positiveFeedbackScoreOptions = [];
            if (
                this.emailSentimentType == "stars" ||
                this.emailSentimentType == "hearts"
            ) {
                this.positiveFeedbackScoreOptions = ["1", "2", "3", "4", "5"];
                this.positiveFeedbackScore = "3";
            } else if (this.emailSentimentType == "thumbs") {
                this.positiveFeedbackScoreOptions = ["1"];
                this.positiveFeedbackScore = "1";
            } else {
                this.positiveFeedbackScoreOptions = [
                    "1",
                    "2",
                    "3",
                    "4",
                    "5",
                    "6",
                    "7",
                    "8",
                    "9",
                    "10"
                ];
                this.positiveFeedbackScore = "5";
            }
        },
        //Change positive landing page link
        changePositiveLandingPageLink() {
            for (let i = 0; i < this.landingPageOptions.length; i++) {
                if (
                    this.positiveLandingPageId == this.landingPageOptions[i].LandingPageId
                ) {
                    this.positiveLandingPageLink = this.landingPageOptions[
                        i
                    ].LandingPageUrl;
                }
            }
        },
        //Change negitive landing page link
        changeNegativeLandingPageLink() {
            for (let i = 0; i < this.landingPageOptions.length; i++) {
                if (
                    this.negativeLandingPageId == this.landingPageOptions[i].LandingPageId
                ) {
                    this.negativeLandingPageLink = this.landingPageOptions[
                        i
                    ].LandingPageUrl;
                }
            }
        },
        //Get list of review source
        getListingInfo() {
            let getListingInfoParams = {
                CompanyId: this.locationId
            };
            this.$vs.loading();
            this.axios
                .post("/ws/ReviewGather/GetListingInfo", getListingInfoParams)
                .then(response => {
                    let data = response.data;
                    this.reviewSources = [];
                    for (var i = 0; data.length > i; i++) {
                        this.reviewSources.push({
                            RemoteSourceId: data[i].ListingSourceId,
                            SourceName: data[i].ListingSource,
                            SourceListingStatus: data[i].ListingStatus
                        });
                        this.selectedReviewSourceChecked[i] = false;
                        this.reviewSiteUrl[i] = "";
                        this.selectedReviewSource[i] = "";
                    }
                    this.$vs.loading.close();
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);
                });
        },
        //Push or Pop value of review source when campaign creating
        pushPopReviewSource(e, reviewSource, i) {
            if (e.type == "change" && e.target.checked) {
                this.selectedReviewSource[i] = reviewSource;
            }
            if (e.type == "change" && !e.target.checked) {
                this.selectedReviewSource[i] = "";
                this.reviewSiteUrl[i] = "";
            }
        },
        //Get list of message template
        listMessageTemplates() {
            let listMessageTemplatesParams = {
                LocationId: this.locationId
            };
            this.$vs.loading();
            this.axios
                .post(
                    "/ws/ReviewGather/ListMessageTemplates",
                    listMessageTemplatesParams
                )
                .then(response => {
                    let data = response.data;
                    this.messageTemplates = data;
                    let i = 0;
                    this.messageTemplates.map(function (manipulatedData) {
                        manipulatedData.index = i;
                        i++;
                        return manipulatedData;
                    });
                    this.selectedTemplate = 0;
                    this.$vs.loading.close();
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);
                });
        },
        //Copy existing email template and populate details in all field
        copyEmailTemplate() {
            let templateData = this.messageTemplates[this.selectedTemplate];
            this.emailName = templateData.TemplateName;
            this.emailSubject = templateData.Subject;
            this.replyEmail = templateData.ReplyAddress;
            this.image = templateData.HeaderImage;
            this.content = templateData.EmailMsg;
            this.onDay =
                templateData.OnDay == 0 ? "0" : templateData.OnDay.toString();
            this.timeHour = templateData.TimeHour.toString();
            this.timeMinute =
                templateData.TimeMinute == 0 ?
                "00" :
                this.minutesFormat(templateData.TimeMinute);
            this.AMPM = templateData.AMPM;
            this.sendRule = templateData.SendRule;
            this.emailCampaignId = templateData.CampaignId;
            this.emailCampaignName = templateData.CampaignName;
            this.emailGoogleReviewPrioritization =
                templateData.GoogleReviewPrioritization;
            this.emailGreeting = templateData.Greeting;
            this.emailTitlefont = templateData.Titlefont;
            this.emailisDeleted = templateData.isDeleted;
        },
        insertMergeTag() {
            this.content = this.content + this.selectInsertMerge;
            let quill = this.editor;
            if (quill.getSelection()) {
                const selection = quill.getSelection();
                quill.insertText(selection.index, this.selectInsertMerge);
            } else {
                quill.focus();
                const selection = quill.getSelection();
                quill.insertText(selection.index, this.selectInsertMerge);
            }
        },
        //Get list of media
        listMedia() {
            let listMediaParams = {
                LocationId: this.locationId
            };
            this.$vs.loading();
            this.axios
                .post("/ws/ReviewGather/ListMedia", listMediaParams)
                .then(response => {
                    let data = response.data;
                    this.mediaList = data;
                    this.$vs.loading.close();
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);
                });
        },
        //Delete media
        deleteMedia(id) {
            this.$vs.loading();
            this.axios
                .get("/ws/ReviewGather/DeleteMedia?id=" + id)
                .then(() => {
                    this.listMedia();
                    this.$vs.loading.close();
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);
                });
        },
        //Add media
        addMedia(imageByteCode) {
            let addMediaParams = {
                LocationId: this.locationId,
                MediaId: 0,
                MediaName: "",
                FileContents: imageByteCode
            };
            this.$vs.loading();
            this.axios
                .post("/ws/ReviewGather/AddMedia", addMediaParams)
                .then(() => {
                    this.listMedia();
                    this.$vs.loading.close();
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);
                });
        },
        //Convert image into base64 code
        convertImageFileToByteArray() {
            // console.log('upload image called!');
            /*
                      Initialize the form data
                  */
            let formData = new FormData();

            /*
                      Add the form data we need to submit
                  */
            formData.append("myFile", this.file);

            /*
                      Make the request to the POST /single-file URL
                  */
            this.$vs.loading();
            this.axios
                .post("/ws/ReviewGather/ConvertImageFileToByteArray", formData, {
                    "Content-Type": "multipart/form-data"
                })
                .then(response => {
                    // this.headerImage = response.data
                    this.addMedia(response.data);
                    this.file = "";
                    this.$refs.mediaFile.value = "";
                    this.$vs.notify({
                        title: "Success",
                        text: "File uploaded successfully.",
                        color: "success",
                        iconPack: "feather",
                        icon: "icon-check",
                        position: "top-right",
                        time: 4000
                    });
                    this.$vs.loading.close();
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);
                    this.$vs.notify({
                        title: "Error",
                        text: e.response.data.Errors[0].Message,
                        color: "danger",
                        iconPack: "feather",
                        icon: "icon-alert-circle",
                        position: "top-right",
                        time: 4000
                    });
                });
        },
        //Validate image and create
        handleFileUpload(e) {
            if (
                this.$refs.mediaFile.files[0].type == "image/png" ||
                this.$refs.mediaFile.files[0].type == "image/gif" ||
                this.$refs.mediaFile.files[0].type == "image/jpg" ||
                this.$refs.mediaFile.files[0].type == "image/jpeg"
            ) {
                this.file = this.$refs.mediaFile.files[0];
                var files = e.target.files || e.dataTransfer.files;
                if (!files.length) return;
                this.convertImageFileToByteArray();
            } else {
                this.file = "";
                this.$refs.mediaFile.value = "";
            }
        },
        //Send email to test email template before it's created or updated
        sendTestEmail() {
            this.$validator.validateAll("testEmailForm").then(result => {
                if (result) {
                    let testEmailContacts = [{
                        CustomField: "MyCustomerFieldValue",
                        Email: this.testEmailName,
                        FirstName: "Bob",
                        LastName: "Smith",
                        Phone: "999-999-9999"
                    }];

                    let testEmailCurrentTemplateData;
                    if (this.IsFirstParty) {
                        testEmailCurrentTemplateData = {
                            EmailMsg: this.content,
                            HeaderImage: this.image,
                            IsFirstParty: this.IsFirstParty,
                            ReplyAddress: this.replyEmail,
                            Subject: this.emailSubject,
                            TemplateId: 0,
                            TemplateName: "Test Email"
                        };
                    } else {
                        let reviewCampaignSources = [];
                        for (var i = 0; this.selectedReviewSource.length > i; i++) {
                            if (this.selectedReviewSource[i] != "") {
                                let overrideUrlEnabled;
                                if (this.reviewSiteUrl[i]) {
                                    overrideUrlEnabled = true;
                                } else {
                                    overrideUrlEnabled = false;
                                }
                                reviewCampaignSources.push({
                                    SourceId: 0,
                                    RemoteSourceId: this.selectedReviewSource[i].RemoteSourceId,
                                    SourceName: this.selectedReviewSource[i].SourceName,
                                    OverrideUrlEnabled: overrideUrlEnabled,
                                    OverrideUrl: this.reviewSiteUrl[i]
                                });
                            }
                        }
                        testEmailCurrentTemplateData = {
                            EmailMsg: this.content,
                            HeaderImage: this.image,
                            IsFirstParty: this.IsFirstParty,
                            ReplyAddress: this.replyEmail,
                            Subject: this.emailSubject,
                            TemplateId: 0,
                            TemplateName: "Test Email",
                            Sources: reviewCampaignSources
                        };
                    }

                    let sendTestEmailParams;
                    if (this.IsEmailToLandingPage) {
                        sendTestEmailParams = {
                            FirstPartyUrl: this.widgetUrl,
                            LocationId: this.locationId,
                            testEmailAddress: this.testEmailName,
                            IsEmailToLandingPage: this.IsEmailToLandingPage,
                            EmailFeedbackType: this.emailSentimentType,
                            PositiveFeedbackScore: this.positiveFeedbackScore,
                            PositiveLandingPageId: this.positiveLandingPageId,
                            NegativeLandingPageId: this.negativeLandingPageId,
                            Contacts: testEmailContacts,
                            CurrentTemplateData: testEmailCurrentTemplateData
                        };
                    } else {
                        sendTestEmailParams = {
                            FirstPartyUrl: this.widgetUrl,
                            LocationId: this.locationId,
                            testEmailAddress: this.testEmailName,
                            IsEmailToLandingPage: this.IsEmailToLandingPage,
                            Contacts: testEmailContacts,
                            CurrentTemplateData: testEmailCurrentTemplateData
                        };
                    }
                    this.$vs.loading();
                    this.axios
                        .post(
                            "/ws/ReviewGather/SendReviewRequestTestEmail",
                            sendTestEmailParams
                        )
                        .then(() => {
                            this.$vs.notify({
                                title: "Success",
                                text: "Test email sent successfully.",
                                color: "success",
                                iconPack: "feather",
                                icon: "icon-check",
                                position: "top-right",
                                time: 4000
                            });
                            this.$vs.loading.close();
                        })
                        .catch(e => {
                            this.$vs.loading.close();
                            this.showError(e);
                            this.$vs.notify({
                                title: "Error",
                                text: e.response.data.Errors[0].Message,
                                color: "danger",
                                iconPack: "feather",
                                icon: "icon-alert-circle",
                                position: "top-right",
                                time: 4000
                            });
                        });
                }
            });
        },
        //Cancel new drip email add process and clear all fields
        cancelNewDripEmail() {
            this.addCampaignPopupActive = true;
            this.isEmailTemplateCopied = false;
            this.addDripEmail = false;
            (this.dripEmailPopupTitle = "Create New Drip Email"),
            (this.dripEmailEditMode = false);
            this.selectedTemplate = null;
            this.tempalateId = -1;
            this.emailName = null;
            this.emailSubject = null;
            this.replyEmail = null;
            this.image = "";
            this.imageErrorMessage = null;
            this.onDayErrorMessage = null;
            this.content = null;
            this.onDay = "0";
            this.timeHour = "10";
            this.timeMinute = "15";
            this.AMPM = "am";
            this.sendRule = 1;
            this.emailCampaignId = 0;
            this.emailCampaignName = null;
            this.emailGoogleReviewPrioritization = false;
            this.emailGreeting = "";
            this.emailTitlefont = "";
            this.emailisDeleted = false;
            this.testEmailName = null;
            this.editableMessageTemplateIndex = null;

            setTimeout(() => {
                this.errors.clear("createNewDripEmailForm");
                this.errors.clear("testEmailForm");
            }, 20);

            setTimeout(() => {
                this.isDripEmailPopupActive = false;
            }, 1000);
        },
        //add message template in array of Message template when creating or updating
        addMessageTemplate() {
            this.$validator.validateAll("createNewDripEmailForm").then(result => {
                if (result) {
                    if (this.image == "") {
                        this.imageErrorMessage = "Please select a header image.";
                        return false;
                    }
                    this.imageErrorMessage = null;
                    if (this.addMessageTemplates.length > 0) {
                        for (var j = 0; this.addMessageTemplates.length > j; j++) {
                            if (this.editableMessageTemplateIndex !== null) {
                                if (this.editableMessageTemplateIndex != j) {
                                    if (this.addMessageTemplates[j].OnDay == this.onDay) {
                                        this.onDayErrorMessage =
                                            "Wait period already used. Please select a different wait period.";
                                        return false;
                                    }
                                }
                            } else {
                                if (this.addMessageTemplates[j].OnDay == this.onDay) {
                                    this.onDayErrorMessage =
                                        "Wait period already used. Please select a different wait period.";
                                    return false;
                                }
                            }
                        }
                        this.onDayErrorMessage = null;
                    }
                    if (this.isEmailTemplateCopied) {
                        this.addMessageTemplates.push({
                            TemplateName: this.emailName,
                            Subject: this.emailSubject,
                            ReplyAddress: this.replyEmail,
                            HeaderImage: this.image,
                            EmailMsg: this.content,
                            OnDay: this.onDay,
                            TimeHour: this.timeHour,
                            TimeMinute: this.timeMinute,
                            AMPM: this.AMPM,
                            SendRule: this.sendRule,
                            // TemplateId: -1,
                            TemplateId: this.tempalateId,
                            Title: "myTitle",
                            CampaignId: this.emailCampaignId,
                            CampaignName: this.emailCampaignName,
                            GoogleReviewPrioritization: this.emailGoogleReviewPrioritization,
                            Greeting: this.emailGreeting,
                            Titlefont: this.emailTitlefont,
                            isDeleted: this.emailisDeleted
                        });
                    } else {
                        this.addMessageTemplates.push({
                            TemplateName: this.emailName,
                            Subject: this.emailSubject,
                            ReplyAddress: this.replyEmail,
                            HeaderImage: this.image,
                            EmailMsg: this.content,
                            OnDay: this.onDay,
                            TimeHour: this.timeHour,
                            TimeMinute: this.timeMinute,
                            AMPM: this.AMPM,
                            SendRule: this.sendRule,
                            // TemplateId: -1,
                            TemplateId: this.tempalateId,
                            Title: "myTitle"
                        });
                    }
                    if (this.editableMessageTemplateIndex !== null) {
                        this.addMessageTemplates.splice(
                            this.editableMessageTemplateIndex,
                            1
                        );
                    }
                    this.cancelNewDripEmail();
                }
            });
        },
        //Delete drip email when campaign create or update
        deleteDripEmail() {
            this.addMessageTemplates.splice(this.deletableDripEmailIndex, 1);
            this.deletableDripEmailIndex = null;
            this.deleteDripEmailPopupActive = false;
        },
        //Add campaign
        addCampaign() {
            if (this.addMessageTemplates.length <= 0) {
                this.addMessageTemplatesErrorMessage =
                    "Please add at least one drip email.";
                return false;
            }
            let addCampaignParams;
            if (this.IsFirstParty) {
                if (this.IsEmailToLandingPage) {
                    addCampaignParams = {
                        CampaignId: -1,
                        CampaignName: this.campaignName,
                        IsFirstParty: this.IsFirstParty,
                        IsEmail: true,
                        FirstPartyUrl: this.widgetUrl,
                        LocationId: this.locationId,
                        IsEmailToLandingPage: this.IsEmailToLandingPage,
                        EmailFeedbackType: this.emailSentimentType,
                        PositiveFeedbackScore: this.positiveFeedbackScore,
                        PositiveLandingPageId: this.positiveLandingPageId,
                        NegativeLandingPageId: this.negativeLandingPageId,
                        MessageTemplates: this.addMessageTemplates
                    };
                } else {
                    addCampaignParams = {
                        CampaignId: -1,
                        CampaignName: this.campaignName,
                        IsFirstParty: this.IsFirstParty,
                        IsEmail: true,
                        FirstPartyUrl: this.widgetUrl,
                        LocationId: this.locationId,
                        IsEmailToLandingPage: this.IsEmailToLandingPage,
                        MessageTemplates: this.addMessageTemplates
                    };
                }
            } else {
                let reviewCampaignSources = [];
                for (var i = 0; this.selectedReviewSource.length > i; i++) {
                    if (this.selectedReviewSource[i] != "") {
                        reviewCampaignSources.push({
                            SourceId: 0,
                            RemoteSourceId: this.selectedReviewSource[i].RemoteSourceId,
                            SourceName: this.selectedReviewSource[i].SourceName,
                            OverrideUrlEnabled: true,
                            OverrideUrl: this.reviewSiteUrl[i]
                        });
                    }
                }
                if (this.IsEmailToLandingPage) {
                    addCampaignParams = {
                        CampaignId: -1,
                        CampaignName: this.campaignName,
                        IsFirstParty: this.IsFirstParty,
                        IsEmail: true,
                        LocationId: this.locationId,
                        IsEmailToLandingPage: this.IsEmailToLandingPage,
                        EmailFeedbackType: this.emailSentimentType,
                        PositiveFeedbackScore: this.positiveFeedbackScore,
                        PositiveLandingPageId: this.positiveLandingPageId,
                        NegativeLandingPageId: this.negativeLandingPageId,
                        MessageTemplates: this.addMessageTemplates,
                        ReviewCampaignSources: reviewCampaignSources
                    };
                } else {
                    addCampaignParams = {
                        CampaignId: -1,
                        CampaignName: this.campaignName,
                        IsFirstParty: this.IsFirstParty,
                        IsEmail: true,
                        LocationId: this.locationId,
                        IsEmailToLandingPage: this.IsEmailToLandingPage,
                        MessageTemplates: this.addMessageTemplates,
                        ReviewCampaignSources: reviewCampaignSources
                    };
                }
            }
            this.$vs.loading();
            this.axios
                .post("/ws/ReviewGather/AddCampaign", addCampaignParams)
                .then(() => {
                    this.listCampaigns();
                    this.closeAddCampaignPopup();
                    this.$vs.notify({
                        title: "Success",
                        text: "Campaign saved successfully.",
                        color: "success",
                        iconPack: "feather",
                        icon: "icon-check",
                        position: "top-right",
                        time: 4000
                    });
                    this.$vs.loading.close();
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);
                    this.$vs.notify({
                        title: "Error",
                        text: e.response.data.Errors[0].Message,
                        color: "danger",
                        iconPack: "feather",
                        icon: "icon-alert-circle",
                        position: "top-right",
                        time: 4000
                    });
                });
        },
        //Close add campaign popup and clear fields
        closeAddCampaignPopup() {
            this.editCampaignMode = false;
            this.campaignPopupTitle = "Create New Review Gathering Campaign";
            this.addCampaignPopupActive = false;
            this.activeStep = 1;
            this.completedStepArr = [];
            this.progressBar = 20;

            this.IsFirstParty = null;

            this.IsEmailToLandingPage = null;

            this.emailSentimentType = "stars";
            this.positiveFeedbackScore = "3";
            this.positiveFeedbackScoreOptions = ["1", "2", "3", "4", "5"];
            this.positiveLandingPageId = null;
            this.negativeLandingPageId = null;
            this.positiveLandingPageLink = null;
            this.negativeLandingPageLink = null;

            this.campaignName = null;
            this.widgetUrl = null;
            for (var i = 0; this.reviewSources.length > i; i++) {
                this.selectedReviewSourceChecked[i] = false;
                this.reviewSiteUrl[i] = "";
                this.selectedReviewSource[i] = "";
            }
            this.reviewCheckErrorMessage = null;

            this.addDripEmail = false;
            this.messageTemplates = [];

            this.addMessageTemplates = [];
            this.addMessageTemplatesErrorMessage = null;
        },
        //Get details of campaign
        getCampaignId(id) {
            this.$vs.loading();
            this.axios
                .get("/ws/ReviewGather/GetCampaignId?id=" + id)
                .then(response => {
                    let data = response.data;
                    this.progressBar = 25;
                    this.campaignId = data.CampaignId;
                    this.IsFirstParty = data.IsFirstParty;

                    this.IsEmailToLandingPage = data.IsEmailToLandingPage;
                    if (data.IsEmailToLandingPage) {
                        this.emailSentimentType = data.EmailFeedbackType;
                        this.activeStep = 3;
                    } else {
                        this.activeStep = 4;
                    }
                    this.positiveFeedbackScore = data.PositiveFeedbackScore;
                    this.positiveLandingPageId = data.PositiveLandingPageId;
                    this.negativeLandingPageId = data.NegativeLandingPageId;

                    for (let j = 0; j < this.landingPageOptions.length; j++) {
                        if (
                            this.landingPageOptions[j].LandingPageId ==
                            this.positiveLandingPageId
                        ) {
                            this.positiveLandingPageLink = this.landingPageOptions[
                                j
                            ].LandingPageUrl;
                        }
                        if (
                            this.landingPageOptions[j].LandingPageId ==
                            this.negativeLandingPageId
                        ) {
                            this.negativeLandingPageLink = this.landingPageOptions[
                                j
                            ].LandingPageUrl;
                        }
                    }

                    this.campaignName = data.CampaignName;
                    this.widgetUrl = data.FirstPartyUrl;

                    this.selectedReviewSource = [];
                    let sourceIds = [];
                    for (var i = 0; data.ReviewCampaignSources.length > i; i++) {
                        sourceIds.push(data.ReviewCampaignSources[i].RemoteSourceId);
                    }
                    for (let i = 0; i < this.reviewSources.length; i++) {
                        if (sourceIds.includes(this.reviewSources[i].RemoteSourceId)) {
                            let ij;
                            for (let j = 0; j < data.ReviewCampaignSources.length; j++) {
                                if (
                                    this.reviewSources[i].RemoteSourceId ==
                                    data.ReviewCampaignSources[j].RemoteSourceId
                                ) {
                                    ij = j;
                                }
                            }
                            this.selectedReviewSourceChecked[i] = true;
                            this.reviewSiteUrl[i] =
                                data.ReviewCampaignSources[ij].OverrideUrl;
                            this.selectedReviewSource[i] = {
                                RemoteSourceId: data.ReviewCampaignSources[ij].RemoteSourceId,
                                SourceName: data.ReviewCampaignSources[ij].SourceName,
                                SourceListingStatus: "accurate"
                            };
                        } else {
                            this.selectedReviewSourceChecked[i] = false;
                            this.reviewSiteUrl[i] = "";
                            this.selectedReviewSource[i] = "";
                        }
                    }
                    this.addMessageTemplates = data.MessageTemplates;
                    this.$vs.loading.close();
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);
                });
        },
        //Update campaign
        updateCampaign() {
            if (this.addMessageTemplates.length <= 0) {
                this.addMessageTemplatesErrorMessage =
                    "Please add at least one drip email.";
                return false;
            }
            let editCampaignParams;
            if (this.IsFirstParty) {
                if (this.IsEmailToLandingPage) {
                    editCampaignParams = {
                        CampaignId: this.campaignId,
                        CampaignName: this.campaignName,
                        IsFirstParty: this.IsFirstParty,
                        IsEmail: true,
                        FirstPartyUrl: this.widgetUrl,
                        LocationId: this.locationId,
                        IsEmailToLandingPage: this.IsEmailToLandingPage,
                        EmailFeedbackType: this.emailSentimentType,
                        PositiveFeedbackScore: this.positiveFeedbackScore,
                        PositiveLandingPageId: this.positiveLandingPageId,
                        NegativeLandingPageId: this.negativeLandingPageId,
                        MessageTemplates: this.addMessageTemplates,
                        ReviewCampaignSources: []
                    };
                } else {
                    editCampaignParams = {
                        CampaignId: this.campaignId,
                        CampaignName: this.campaignName,
                        IsFirstParty: this.IsFirstParty,
                        IsEmail: true,
                        FirstPartyUrl: this.widgetUrl,
                        LocationId: this.locationId,
                        IsEmailToLandingPage: this.IsEmailToLandingPage,
                        MessageTemplates: this.addMessageTemplates,
                        ReviewCampaignSources: []
                    };
                }
            } else {
                let reviewCampaignSources = [];
                for (var i = 0; this.selectedReviewSource.length > i; i++) {
                    if (this.selectedReviewSource[i] != "") {
                        reviewCampaignSources.push({
                            SourceId: 0,
                            RemoteSourceId: this.selectedReviewSource[i].RemoteSourceId,
                            SourceName: this.selectedReviewSource[i].SourceName,
                            OverrideUrlEnabled: true,
                            OverrideUrl: this.reviewSiteUrl[i]
                        });
                    }
                }
                if (this.IsEmailToLandingPage) {
                    editCampaignParams = {
                        CampaignId: this.campaignId,
                        CampaignName: this.campaignName,
                        IsFirstParty: this.IsFirstParty,
                        IsEmail: true,
                        LocationId: this.locationId,
                        IsEmailToLandingPage: this.IsEmailToLandingPage,
                        EmailFeedbackType: this.emailSentimentType,
                        PositiveFeedbackScore: this.positiveFeedbackScore,
                        PositiveLandingPageId: this.positiveLandingPageId,
                        NegativeLandingPageId: this.negativeLandingPageId,
                        MessageTemplates: this.addMessageTemplates,
                        ReviewCampaignSources: reviewCampaignSources
                    };
                } else {
                    editCampaignParams = {
                        CampaignId: this.campaignId,
                        CampaignName: this.campaignName,
                        IsFirstParty: this.IsFirstParty,
                        IsEmail: true,
                        LocationId: this.locationId,
                        IsEmailToLandingPage: this.IsEmailToLandingPage,
                        MessageTemplates: this.addMessageTemplates,
                        ReviewCampaignSources: reviewCampaignSources
                    };
                }
            }
            this.$vs.loading();
            this.axios
                .post("/ws/ReviewGather/UpdateCampaign", editCampaignParams)
                .then(() => {
                    this.listCampaigns();
                    this.closeAddCampaignPopup();
                    this.$vs.notify({
                        title: "Success",
                        text: "Campaign saved successfully.",
                        color: "success",
                        iconPack: "feather",
                        icon: "icon-check",
                        position: "top-right",
                        time: 4000
                    });
                    this.$vs.loading.close();
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);
                    this.$vs.notify({
                        title: "Error",
                        text: e.response.data.Errors[0].Message,
                        color: "danger",
                        iconPack: "feather",
                        icon: "icon-alert-circle",
                        position: "top-right",
                        time: 4000
                    });
                });
        },
        //Retrive details of email template that would be updated
        editMessageDripEmailTemplate(index) {
            this.editableMessageTemplateIndex = index;
            let data;

            this.isEmailTemplateCopied =
                Object.keys(this.addMessageTemplates[index]).length > 12 ? true : false;
            data = this.addMessageTemplates[index];

            this.tempalateId = data.TemplateId;
            this.emailName = data.TemplateName;
            this.emailSubject = data.Subject;
            this.replyEmail = data.ReplyAddress;
            this.image = data.HeaderImage;
            this.content = data.EmailMsg;
            this.onDay = data.OnDay.toString();
            this.timeHour = data.TimeHour.toString();
            this.timeMinute = this.minutesFormat(data.TimeMinute);
            this.AMPM = data.AMPM;
            this.sendRule = data.SendRule;
            // this.selectedTemplate = data.TemplateId
            this.emailCampaignId = data.CampaignId;
            this.emailCampaignName = data.CampaignName;
            this.emailGoogleReviewPrioritization = data.GoogleReviewPrioritization;
            this.emailGreeting = data.Greeting;
            this.emailTitlefont = data.Titlefont;
            this.emailisDeleted = data.isDeleted;
            this.addDripEmail = true;

            this.listMessageTemplates();
        }
    }
};
</script>
